import React, { useState, useEffect } from 'react';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import SchoolIcon from '@mui/icons-material/School';

import Typography from '@mui/material/Typography';

import useWindowDimensions from '../components/hooks/useWindowDimensions.js';

import '../scss/education.css';

const educationList = [
  { duration: 'Jan 2016 - May 2017', school: 'Florida International University', program: 'Masters in Computer Science' },
  { duration: 'Aug 2011 - Jun 2015', school: 'Ghulam Ishaq Khan Institute', program: 'Bachelors in Computer Science' },
];

interface Props {
  duration: string;
  company: string;
  title: string;
}

const ComponentItem = ({
  duration, company, title,
}: Props) => (
  <>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      {duration}
    </Typography>
    <Typography sx={{ fontSize: 24, fontWeight: 'bold' }} color="#b2321e" component="div">
      {company}
    </Typography>
    <Typography sx={{ mb: 1.5 }} color="text.secondary">
      {title}
    </Typography>
  </>
);

const Education = () => {
  const { height, width } = useWindowDimensions();
  const [showMobileView, setShowMobileView] = useState(false);
  const [showDesktopView, setShowDesktopView] = useState(true);

  // Effects
  useEffect(() => {
    if (width < 600) {
      setShowDesktopView(false);
      setShowMobileView(true);
    } else {
      setShowMobileView(false);
      setShowDesktopView(true);
    }
  }, [width]);

  return (
    <div className="education-ctn" id="education">
      <div className="heading">Education</div>

      {showDesktopView ? (
        <div className="timeline-desktop">
          <Timeline position="alternate">
            {educationList.map((education) => (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="error">
                    <SchoolIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <ComponentItem
                    duration={education.duration}
                    company={education.school}
                    title={education.program}
                  />
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </div>
      ) : null}

      {showMobileView ? (
        <div className="timeline-mobile">
          {educationList.map((education) => (
            <>
              <TimelineSeparator>
                <TimelineDot color="error">
                  <SchoolIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <ComponentItem
                  duration={education.duration}
                  company={education.school}
                  title={education.program}
                />
              </TimelineContent>
            </>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Education;
