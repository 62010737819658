/* eslint-disable no-return-assign */
import React, { useState } from 'react';

import * as Scroll from 'react-scroll';
import {
  Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller,
} from 'react-scroll';

import {
  NavLink,
} from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';

import '../scss/app.css';
import '../scss/navbar.css';

const NavBar = () => {
  const [navBarActive, setNavBarActive] = useState(false);

  const navBarHandler = () => {
    setNavBarActive(!navBarActive);
  };

  const onClickNavItem = () => {
    setNavBarActive(false);
  };

  const menuIconHtml = navBarActive ? (<MenuBookIcon className="nav-bar-icon" sx={{ fontSize: 30 }} onClick={navBarHandler} />) : (<MenuIcon className="nav-bar-icon" sx={{ fontSize: 30 }} onClick={navBarHandler} />);
  return (
    <>
      <div className="mobile-nav-bar">{menuIconHtml}</div>
      <nav className={`nav-bar ${navBarActive ? 'active' : ''}`}>
        <div className="logo"><Link to="home" containerId="containerElement" spy smooth>VC</Link></div>
        <ul className="nav-bar-list">
          <li className="nav-bar-item">
            <Link activeClass="selected" to="home" containerId="containerElement" spy smooth onSetActive={onClickNavItem}>Home</Link>
          </li>
          <li className="nav-bar-item">
            <Link to="aboutme" activeClass="selected" containerId="containerElement" spy smooth onSetActive={onClickNavItem}>About me</Link>
          </li>
          <li className="nav-bar-item">
            <Link to="experience" activeClass="selected" containerId="containerElement" spy smooth onSetActive={onClickNavItem}>Experience</Link>
          </li>
          <li className="nav-bar-item">
            <Link to="education" activeClass="selected" containerId="containerElement" spy smooth onSetActive={onClickNavItem}>Education</Link>
          </li>
        </ul>
        <div className="social-media-links">
          <TwitterIcon
            sx={{ fontSize: 30 }}
            className="social-media-icon"
            onClick={() => window.open('https://twitter.com/vishchhatwani', '_blank')}
          />
          <LinkedInIcon
            sx={{ fontSize: 30 }}
            className="social-media-icon"
            onClick={() => window.open('https://www.linkedin.com/in/vishal-chhatwani-570a9a86/', '_blank')}
          />
          <GitHubIcon
            sx={{ fontSize: 30 }}
            className="social-media-icon"
            onClick={() => window.open('https://github.com/vishal-chhatwani', '_blank')}
          />
          <InstagramIcon
            sx={{ fontSize: 30 }}
            className="social-media-icon"
            onClick={() => window.open('https://www.instagram.com/travelwithvishalchhatwani/', '_blank')}
          />
        </div>
      </nav>
    </>
  );
};

export default NavBar;
