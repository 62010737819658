import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import * as Scroll from 'react-scroll';
import {
  Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller,
} from 'react-scroll';

import NavBar from './components/NavBar';

import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import Experience from './pages/Experience';
import Education from './pages/Education';
import ContactMe from './pages/ContactMe';

const App = () => (
  // <Router>
  <div className="app-container">

    <NavBar />
    <div className="app-content" id="containerElement">
      <Home />
      <AboutMe />
      <Experience />
      <Education />
      <ContactMe />
    </div>

  </div>
  // </Router>
);

export default App;
