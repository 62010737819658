import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import Emoji
  from '../components/Emoji.jsx';

import '../scss/aboutme.css';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    margin: '2rem 1rem',

  },
  bottom: {
    color: '#fff',
  },
  top: {
    color: '#b2321e',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  progressText: {
    position: 'absolute',
    top: '32%',
    left: '35%',
    color: '#616161',
    fontSize: '1.5rem',
  },
  text: {
    color: '#616161',
    letterSpacing: '2px',
    textTransform: 'uppercase',
  },
}));

interface CircularProgressProps {
  value: number;
  text: string;
  progressText: string;
}

const MyCircularProgress: React.FC<CircularProgressProps> = ({ value, text, progressText }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={150}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={150}
        thickness={4}
        value={value}
      />
      <div className={classes.progressText}>{progressText}</div>
      <div className={classes.text}>{text}</div>
    </div>
  );
};

interface SkillIconProps {
  children: React.ReactNode;
  name: string;
}

const SkillIcon: React.FC<SkillIconProps> = ({ name, children }) => (
  <div className="skill-icon">
    {children}
    <div className="icon-name">{name}</div>
  </div>
);

const AboutMe = () => (
  <div className="aboutme-ctn" id="aboutme">
    <div className="title">
      ABOUT
      {' '}
      <span>ME</span>
    </div>
    <div className="description">
      <div className="sentence h1">
        Hello!
        {' '}
        <Emoji symbol={<span>&#128075;</span>} label="wave" />
        {' '}
      </div>
      <div className="sentence">
        I&apos;m Vishal Chhatwani, a Software Engineer currently residing in Pittsburgh, PA.
      </div>
      <div className="sentence">
        I was born and brought up in Pakistan.
        {' '}
        <Emoji symbol={<span>&#127477;&#127472;</span>} />
        {' '}
        If you are into travelling and a Foodie
        {' '}
        <Emoji symbol={<span>&#129368;</span>} label="food" />
        {' '}
        like
        me, you have to visit Pakistan.

      </div>
      <div className="sentence">
        After completing my undergrad in Computer Science from Pakistan, I was forunate enough
        to be able to come to US and fulfil my dream of pursuing Master&apos;s degree and
        working for a promising tech company.
      </div>
      <div className="sentence">
        I&apos;m passionate about working on different projects and learning new technologies.
      </div>
      <div className="sentence">
        Some of my hobbies include playing cricket, watching Netflix, and listening to music.
      </div>
      <div className="sentence">
        I love to travel whenever I can and I&apos;m always looking for new places to explore.
      </div>

    </div>

    <div className="skills">
      <div className="heading">Technical Skills</div>
      <div className="skill-items">
        <SkillIcon name="React">
          <i className="devicon-react-original colored" />
        </SkillIcon>
        <SkillIcon name="Redux">
          <i className="devicon-redux-original colored" />
        </SkillIcon>
        <SkillIcon name="Nodejs">
          <i className="devicon-nodejs-plain colored" />
        </SkillIcon>
        <SkillIcon name="JavaScript">
          <i className="devicon-javascript-plain colored" />
        </SkillIcon>
        <SkillIcon name="MySQL">
          <i className="devicon-mysql-plain colored" />
        </SkillIcon>
        <SkillIcon name="Git">
          <i className="devicon-git-plain colored" />
        </SkillIcon>
        <SkillIcon name="Webpack">
          <i className="devicon-webpack-plain colored" />
        </SkillIcon>
        <SkillIcon name="TypeScript">
          <i className="devicon-typescript-plain colored" />
        </SkillIcon>
        <SkillIcon name="Firebase">
          <i className="devicon-firebase-plain colored" />
        </SkillIcon>
        <SkillIcon name="Sass">
          <i className="devicon-sass-original colored" />
        </SkillIcon>

      </div>
    </div>

  </div>
);

export default AboutMe;
