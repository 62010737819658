import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore/lite';

import db from '../firebase.config.js';

import '../scss/home.css';

const Home = () => {
  const [_title, setTitle] = useState('');
  const [_subtitle, setSubtitle] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const col = collection(db, 'introduction');
    const snapshot = await getDocs(col);
    const value = snapshot.docs.map((doc) => doc.data());
    const { title, subtitle } = value[0];
    setTitle(title);
    setSubtitle(subtitle);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="home-ctn" />
    );
  }
  return (
    <div className="home-ctn" id="home">
      <div className="introduction">
        <div className="title">{_title}</div>
        <div className="subtitle">{_subtitle}</div>
      </div>
      <div className="avatar-ctn" />
    </div>
  );
};

export default Home;
