/* eslint-disable react/jsx-filename-extension */
import React from 'react';

interface Props {
  symbol: Element;
  label: string;
}

const Emoji:React.FC<Props> = ({ symbol, label }) => (
  <span
    className="emoji"
    role="img"
    aria-label={label || ''}
    aria-hidden={label ? 'false' : 'true'}
  >
    {symbol}
  </span>
);
export default Emoji;
