import React, { useState, useEffect } from 'react';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import WorkIcon from '@mui/icons-material/Work';

import Typography from '@mui/material/Typography';

import useWindowDimensions from '../components/hooks/useWindowDimensions.js';

import '../scss/experience.css';

const payPalDescription = ['Responsible for making the acquisition UI for the new PayPal Cashback Mastercard product. Worked across teams integrating Cashback card acquisition experience in PayPal Checkout.',
  'Analyzed the performance of the react app, identifying potential issues and solutions to improve the load time by 60%.'];

const mavenMachinesDescription = [`Responsible for building an IoT solution for manufacturing industry where
we collect real time data from hundreds of machines and let the users visualize their manufacturing production line and
remotely control their machines from a Web Portal built with Reactjs and Nodejs.`,
'Facilitating Frontend Chapter to keep all the squads/teams in sync, to bring standards across the company, and to keep learning from each other.'];

const courierVenturesDescription = [`Worked on building a Backend as well as a Frontend of an system where we connected two or more
people in a courier delivery system from point A to point B.`];

const fiuDescription = [`Worked with AngularJS and Jade to build a virtual health consultant that was aimed at helping people overcome their
alchohol addiction.`];

const tenpearlsDescription = [`My very first job out of school where I got a chance to work on couple of
Android projects and implemented features like push notificaiton, bug tracking integration and automation
testing using Selenium and Appium.`];

const experienceList = [
  {
    id: 1, duration: 'June 2022 - Present', company: 'PayPal Inc', title: 'Software Engineer 3', description: payPalDescription,
  },
  {
    id: 2, duration: 'August 2017 - May 2022', company: 'Maven Machines Inc', title: 'Project Manager | Software Engineer', description: mavenMachinesDescription,
  },
  {
    id: 3, duration: 'January 2017 - May 2017', company: 'Courier Ventures', title: 'Software Engineer Intern', description: courierVenturesDescription,
  },
  {
    id: 4, duration: 'August 2016 - January 2017', company: 'Florida International University', title: 'Research Assistant', description: fiuDescription,
  },
  {
    id: 5, duration: 'July 2015 - December 2015', company: '10Pearls', title: 'Software Developer', description: tenpearlsDescription,
  },
];

interface Props {
  duration: string;
  company: string;
  title: string;
  description: Array;
}

const ComponentItem = ({
  duration, company, title, description,
}: Props) => (
  <>
    <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
      {duration}
    </Typography>
    <Typography sx={{ fontSize: 24, fontWeight: 'bold' }} color="#b2321e" component="div">
      {company}
    </Typography>
    <Typography sx={{ mb: 1.5 }} color="text.secondary">
      {title}
    </Typography>
    <Typography sx={{ fontSize: 14 }} color="text.secondary">
      {description.map((paragraph: string) => (
        <span>
          {paragraph}
          <br />
          <br />
        </span>
      ))}
    </Typography>
  </>
);

const Experience = () => {
  const { height, width } = useWindowDimensions();
  const [showMobileView, setShowMobileView] = useState(false);
  const [showDesktopView, setShowDesktopView] = useState(true);

  // Effects
  useEffect(() => {
    if (width < 600) {
      setShowDesktopView(false);
      setShowMobileView(true);
    } else {
      setShowMobileView(false);
      setShowDesktopView(true);
    }
  }, [width]);

  return (
    <div className="experience-ctn" id="experience">
      <div className="heading">Experience</div>

      {showDesktopView ? (
        <div className="timeline-desktop">
          <Timeline position="alternate">
            {experienceList.map((experience) => (
              <TimelineItem key={experience.id}>
                <TimelineSeparator>
                  <TimelineDot color="error">
                    <WorkIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <ComponentItem
                    duration={experience.duration}
                    company={experience.company}
                    title={experience.title}
                    description={experience.description}
                  />
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </div>
      ) : null}

      {showMobileView ? (
        <div className="timeline-mobile">
          {experienceList.map((experience) => (
            <>
              <TimelineSeparator>
                <TimelineDot color="error">
                  <WorkIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <ComponentItem
                  duration={experience.duration}
                  company={experience.company}
                  title={experience.title}
                  description={experience.description}
                />
              </TimelineContent>
            </>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Experience;
