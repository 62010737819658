import React from 'react';

import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';

import '../scss/contactme.css';

const ContactMe = () => (
  <div className="contact-ctn" id="contactme">
    <div className="heading">Get In Touch</div>
    <div className="contact-info">
      I would love to hear from you. Let&apos;s connect!
    </div>
    <div className="social-media-links-ctn">
      <TwitterIcon
        sx={{ fontSize: 50 }}
        className="social-media-icon"
        onClick={() => window.open('https://twitter.com/vishchhatwani', '_blank')}
      />
      <LinkedInIcon
        sx={{ fontSize: 50 }}
        className="social-media-icon"
        onClick={() => window.open('https://www.linkedin.com/in/vishal-chhatwani-570a9a86/', '_blank')}
      />
      <GitHubIcon
        sx={{ fontSize: 50 }}
        className="social-media-icon"
        onClick={() => window.open('https://github.com/vishal-chhatwani', '_blank')}
      />
      <InstagramIcon
        sx={{ fontSize: 50 }}
        className="social-media-icon"
        onClick={() => window.open('https://www.instagram.com/travelwithvishalchhatwani/', '_blank')}
      />
    </div>
  </div>
);
export default ContactMe;
