// const config = {
//   apiKey: 'AIzaSyB_d9oeAhJ78KKOUCj3Ay1FtkTQCWBr5Yc',
//   authDomain: 'react-typescript-3b7f4.firebaseapp.com',
//   databaseURL: 'https://react-typescript-3b7f4-default-rtdb.firebaseio.com',
// };

// export default config;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB_d9oeAhJ78KKOUCj3Ay1FtkTQCWBr5Yc',
  authDomain: 'react-typescript-3b7f4.firebaseapp.com',
  databaseURL: 'https://react-typescript-3b7f4-default-rtdb.firebaseio.com',
  projectId: 'react-typescript-3b7f4',
  storageBucket: 'react-typescript-3b7f4.appspot.com',
  messagingSenderId: '494725434002',
  appId: '1:494725434002:web:29bc33ab4ffd9ee9b6b44b',
  measurementId: 'G-J0T6KHBSJS',
};

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export default db;
